import { useEffect, useState } from "react";
import { PartenrsService } from "../services/api";
import { RedirectUrl } from "../types/Common";
import { getUrlQueryParams } from "../utils/Url";

export const useInitialUrl = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [googleAdsId, setGoogleAdsId] = useState("");
  const [redirectUrl, setRedirectUrl] = useState<RedirectUrl>({
    params: { accountId: "", transactionId: "" },
    url: "",
  });

  useEffect(() => {
    (async () => {
      const googleAdsId = getUrlQueryParams("googleAdsId");
      const transactionId = getUrlQueryParams("transactionId");
      const redirectUrl = getUrlQueryParams("redirectUrl");
      const token = getUrlQueryParams("token");
      if (token) {
        localStorage.setItem("auth0Token", token);
      }

      const { isTokenValid } =
        await PartenrsService.validatePartnersCustomerToken();

      setIsValidUrl(
        !!googleAdsId && !!transactionId && !!redirectUrl && !!token
      );
      setGoogleAdsId(googleAdsId);
      setIsAuthenticated(isTokenValid);
      setRedirectUrl({
        params: { accountId: googleAdsId, transactionId },
        url: redirectUrl,
      });
      setIsLoading(false);
    })();

    return () => {
      localStorage.removeItem("auth0Token");
    };
  }, []);

  return [
    isLoading,
    isValidUrl,
    isAuthenticated,
    googleAdsId,
    redirectUrl,
  ] as const;
};
