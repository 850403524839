import React, { FC } from 'react';
import { ERROR_MSG, ResponseCode } from '../../constants';
import { useRedirect } from '../../hooks/useRedirect';
import { RedirectUrl } from '../../types/Common';
import styles from './styles.module.scss';

interface IProps {
  redirectUrl: RedirectUrl;
}

const UnAuthorized: FC<IProps> = ({ redirectUrl }) => {
  useRedirect(redirectUrl, ResponseCode.TokenExpired);

  return (
    <div className={styles.container}>{ERROR_MSG}</div>
  );
};

export default UnAuthorized;