import { FC, useState } from "react";
import { CLIENT_ID, ERROR_MSG, ResponseCode } from "../../constants";
import { useRedirect } from "../../hooks/useRedirect";
import { GoogleService } from "../../services/api";
import { RedirectUrl } from "../../types/Common";
import Error from "../Error";
import styles from "./styles.module.scss";

type IProps = {
	googleAdsId: string;
	redirectUrl: RedirectUrl;
};

const Google: FC<IProps> = ({ googleAdsId, redirectUrl }) => {
	const [connected, setConnected] = useState(false);
	const [error, setError] = useState<{ msg: string; code: number } | null>();
	useRedirect(redirectUrl, ResponseCode.Success, connected);

	const initGoogleClient = (callback: (c: string) => void) => {
		if (window.google) {
			const client = window.google.accounts.oauth2.initCodeClient({
				client_id: CLIENT_ID,
				scope: "openid email profile https://www.googleapis.com/auth/adwords",
				callback: (tokenResponse: { code: string }) => {
					callback(tokenResponse.code);
				},
				redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
			});

			client.requestCode();
		}
	};

	const getGoogleSsoCode = (callback: (c: string) => void) => {
		initGoogleClient(callback);
	};

	const handleGoogleSignIn = async () => {
		setError(null);
		setConnected(false);
		getGoogleSsoCode(async (code) => {
			try {
				await GoogleService.connect({ code });
				const googleHierarchy = await GoogleService.getGoogleHierarchy();

				if (
					googleHierarchy.some(
						(gh) =>
							gh.accountId === googleAdsId ||
							gh.children.some((ghc) => ghc.accountId === googleAdsId)
					)
				) {
					await GoogleService.addGoogleAccount({
						protectedClients: [googleAdsId],
					});
					await GoogleService.completeSetup();
					setConnected(true);
				} else {
					await GoogleService.invalidate();
					setError({ msg: ERROR_MSG, code: ResponseCode.GoogleAdsIdNotFoundError });
				}
			} catch (e) {
				await GoogleService.invalidate();
				setError({ msg: ERROR_MSG, code: ResponseCode.GoogleError });
			}
		});
	};

	return (
		<div className={styles.container}>
			<button onClick={handleGoogleSignIn}>Login With Google</button>
			{connected && <div className={styles.successMsg}>Connected successfuly</div>}
			{error && <Error redirectUrl={redirectUrl} responseCode={error.code} />}
		</div>
	);
};

export default Google;
