import React, { FC } from 'react';
import { ERROR_MSG, ResponseCode } from '../../constants';
import { useRedirect } from '../../hooks/useRedirect';
import { RedirectUrl } from '../../types/Common';
import styles from './styles.module.scss';

interface IProps {
  redirectUrl: RedirectUrl;
  responseCode: ResponseCode;
}

const Error: FC<IProps> = ({ redirectUrl, responseCode }) => {
  useRedirect(redirectUrl, responseCode);

  return (
    <div className={styles.container}>
      {ERROR_MSG}
    </div>
  );
};

export default Error;