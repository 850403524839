import React from 'react';
import logo from '../../assets/images/cheq.png';
import { ReactComponent as OptmyzrIcon } from '../../assets/images/optmyzr.svg';
import styles from './styles.module.scss';


const Header = () => {
    return (
        <div className={styles.container}>
            <OptmyzrIcon />
            <img src={logo} />
        </div>
    );
};

export default Header;