import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
    children: React.ReactNode;
}

const Container: FC<IProps> = ({ children }) => {
    return (
        <div className={styles.container}>{children}</div>
    );
};

export default Container;