import { api } from "../../lib/axios";

export class PartenrsService {
  static validatePartnersCustomerToken = async () => {
    try {
      await api.get("/google/validate_customer_token");
      return { isTokenValid: true };
    } catch (error) {
      return { isTokenValid: false };
    }
  };
}
