import { api } from "../../lib/axios";
import {
	ConnectGoogleRquestPayload,
	AddAcountsRquestPayload,
	GetHierarchyResponse,
} from "../../types/Google";

import { config } from "../../config";

const { cheqApiUrl } = config;

const BASE_URL = `${cheqApiUrl}/api/v2/google`;

export class GoogleService {
	static connect = async (payload: ConnectGoogleRquestPayload) => {
		await api.post(`${BASE_URL}/connect`, payload);
	};

	static addGoogleAccount = async (payload: AddAcountsRquestPayload) => {
		await api.post(`${BASE_URL}/add_accounts`, payload);
	};

	static completeSetup = async () => {
		await api.post(`${BASE_URL}/complete_setup`);
	};

	static getGoogleHierarchy = async (): Promise<GetHierarchyResponse> => {
		const res = await api.get(`${BASE_URL}/get_hierarchy`);
		return res.data;
	};

	static invalidate = async () => {
		const res = await api.post(`${BASE_URL}/invalidate`, {
			isSendSns: false,
		});
		return res.data;
	};
}
