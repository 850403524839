import { useEffect } from "react";
import { ResponseCode } from "../constants";
import { RedirectUrl } from "../types/Common";
import { buildRedirectUrl } from "../utils/Url";

export const useRedirect = (
  redirectUrl: RedirectUrl,
  responseCode: ResponseCode,
  fireRedirect: boolean = true
) => {
  useEffect(() => {
    if (!redirectUrl.url || !fireRedirect) return;

    const timeout = setTimeout(() => {
      redirectUrl.params.responseCode = responseCode;
      const urlWithParams = buildRedirectUrl(redirectUrl);

      window.location.replace(urlWithParams);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [redirectUrl.url, redirectUrl.params, fireRedirect]);
};
