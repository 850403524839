import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useInitialUrl } from "./hooks/useInitialUrl";
import { ResponseCode } from "./constants";
import PrivateRoutes from "./routes/PrivateRoutes";
import Google from "./pages/Google";
import Error from './pages/Error';
import UnAuthorized from "./pages/UnAuthorized";
import Header from "./components/Header";
import Container from "./layout/Container";

function App() {
  const [isLoading, isUrlValid, isAuthenticated, googleAdsId, redirectUrl] = useInitialUrl();

  return (
    <div className="App">
      <Header />
      <Container>
        {isLoading ? <>Loading</> :
          !isUrlValid ? <Error redirectUrl={redirectUrl} responseCode={ResponseCode.InvalidUrlParams} /> : (
            <Router>
              <Routes>
                <Route element={<PrivateRoutes isAuth={isAuthenticated} />}>
                  <Route element={<Google googleAdsId={googleAdsId} redirectUrl={redirectUrl} />} path="/" />
                </Route>
                <Route element={<UnAuthorized redirectUrl={redirectUrl} />} path="/unauthorized" />
              </Routes>
            </Router>
          )}
      </Container>
    </div>
  );
}

export default App;
