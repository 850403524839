import { config } from "../config";

export const CLIENT_ID =
  config.googleClientID ||
  "228834305221-vpjpmr8rd5eaprijm5ipv9hliae551hh.apps.googleusercontent.com";
export const ERROR_MSG = "An error occurred, please try again later";

export enum ResponseCode {
  Success = 2000,
  GoogleError = 2001,
  GoogleAdsIdNotFoundError = 2002,
  TokenExpired = 2003,
  InvalidUrlParams = 2004,
}
