import axios from "axios";
import { config } from "../config";

export const api = axios.create({
  baseURL: config.cqPartnersUrl,
  withCredentials: true,
});

const getHttpHeaders = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("auth0Token")}`,
});


api.interceptors.request.use((config) => {
  config.headers = getHttpHeaders();
  return config;
});

