import { FC } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

interface IProps {
    isAuth: boolean;
}

export const PrivateRoutes: FC<IProps> = ({ isAuth }) => {
    return (
        isAuth ? <Outlet /> : <Navigate to="/unauthorized" />
    );
};

export default PrivateRoutes;