import { RedirectUrl } from "../types/Common";

export const getUrlQueryParams = (param: string): string => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param) || "";
};

export const buildRedirectUrl = (redirectUrl: RedirectUrl) => {
  const urlWithParams = new URL(redirectUrl.url);

  Object.entries(redirectUrl.params).forEach(([key, value]) => {
    urlWithParams.searchParams.append(key, value.toString());
  });

  return urlWithParams;
};
